import React from "react";
import styled from "styled-components";
import { findPriceById, useStipeProductData } from "../../lib/products";
import { findSoftwareId } from "../../lib/store";

interface Props {
  useType: UseType;
  boatType: BoatType;
  period: Period;
  children?: React.ReactNode;
}

const Price: React.FC<Props> = (props) => {
  const { useType, boatType, period, children } = props;
  const stripeData = useStipeProductData();
  return (
    <PriceWrapper>
      <h3>
        €
        {
          findPriceById(findSoftwareId(useType, boatType, period), stripeData)
            .euros
        }
        .
        <sup>
          {
            findPriceById(findSoftwareId(useType, boatType, period), stripeData)
              .cents
          }
        </sup>
      </h3>
      <small>{children}</small>
    </PriceWrapper>
  );
};

export default Price;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin-right: 32px;
  }
`;
