import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaCheck } from "react-icons/fa";

import { Button } from "../../styles/ui.styles";
import {
  Cards3,
  SoftwareCard,
  CardListElement,
  ConfiguratorFlexSection,
  StyledListElement,
  SoftwareSolutions,
  SoftwareSolutionsInner,
  ConfiguratorWrapper,
  WantHardware,
  WantHardwareCheckbox,
  ConfiguratorFlexOverlay,
} from "../../styles/configurator.styles";
import Price from "./price";
import {
  findSoftwareId,
  useCartStore,
  findCorrespondingHardwareId,
  isAvailable,
} from "../../lib/store";
import { useSnackbar } from "notistack";
import { Prettify } from "../../lib/prettify";
import products from "../../../content/products/products.json";

interface Props {
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
}

const YachtPackages: React.FC<Props> = (props) => {
  const { period, setPeriod } = props;
  const { withHardware, setWithHardware, setSoftware, software, hardware } =
    useCartStore();

  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      autoHideDuration: 3000,
      variant: "success",
      className: "snackbar",
    });
  };

  const handleSoftwareChange = (
    useType: UseType,
    boatType: BoatType,
    period: Period
  ) => {
    setSoftware(findSoftwareId(useType, boatType, period));
    // if the software is already in the cart no need to do anything
    if (software?.id === findSoftwareId(useType, boatType, period)) {
      showSnackbar("This packages is already in the cart");
    } else {
      // if there was already a software in the cart
      if (software) {
        showSnackbar(
          `Updated DataBay package to ${Prettify(boatType)} - ${Prettify(
            useType
          )} (paid ${Prettify(period)})`
        );
        // also show notification for added hardware but only when not already in the cart
        if (
          withHardware &&
          hardware?.id !==
            findCorrespondingHardwareId(
              findSoftwareId(useType, boatType, period)
            )
        ) {
          showSnackbar(`Updated hardware to ${Prettify(boatType)}`);
        }
      } else {
        showSnackbar(
          `Added Databay for ${Prettify(boatType)} - ${Prettify(
            useType
          )} to the cart (paid ${Prettify(period)})`
        );
        // also show notification for added hardware
        if (withHardware) {
          showSnackbar(`Added ${Prettify(boatType)} hardware to the cart`);
        }
      }
    }
  };

  const handleWantHardware = () => {
    // * if hardware is not available, don't do anything
    if (software) {
      if (
        products.find(
          (node) => node.id === findCorrespondingHardwareId(software.id)
        )?.available === false
      ) {
        setWithHardware(false);
        return;
      }
    }

    const hardwareBefore = hardware;
    // It is possible that the button is clicked
    setWithHardware(!withHardware);
    // only show snackbar if adding hardware, so withHardware was false before
    // also the button can be clicked while a databay for a different boat type is in the cart so tell that to the user
    if (withHardware === false && software) {
      const name = Prettify(
        products.find((node) => node.id === software?.id)?.boatType as BoatType
      );
      showSnackbar(
        `Added ${name} hardware to match the Databay currently in the cart`
      );
    }
    // show removed snackbar if there was a hardware in the cart before
    if (hardwareBefore) {
      const name = Prettify(
        products.find((node) => node.id === software?.id)?.boatType as BoatType
      );
      showSnackbar(`Removed ${name} hardware from the cart`);
    }
  };

  return (
    <>
      <ConfiguratorWrapper>
        <WantHardware>
          <WantHardwareCheckbox
            checked={!withHardware}
            onChange={handleWantHardware}
            className="custom"
            id="checkbox"
            type="checkbox"
          />
          <p>I am only looking for software solutions</p>
        </WantHardware>
        <ConfiguratorFlexSection>
          <div>
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              src={"../../images/coach_boat_pro1.jpg"}
              alt={"Coachboat Pro"}
              objectFit={"contain"}
            />
          </div>
          <div>
            <h3>Coachboat Pro Details</h3>
            <p>
              Coachboat Pro is an advanced solution designed specifically for
              coaches, which allows monitoring and analysis of wind, current and
              waves in real time. After the training sync with your own cloud
              for further analysis.
            </p>
            <ul>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Compact and modular design
                </StyledListElement>
                <p>
                  The anemometer is located on the top of a carbon mast. The
                  tensioning ropes can be attached to the mast, which ensure
                  accurate measurement even in choppy water. The complete system
                  is easy to carry and has a modular design.{" "}
                </p>
              </li>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Easy to carry
                </StyledListElement>
                <p>
                  Pack it up, freight it, check it in as luggage. Weather
                  station&apos;s pole is made of three sections and fits into a
                  robust travel case.
                </p>
              </li>
            </ul>
            {/* <Button primary={true}>MORE DETAILS</Button> */}
          </div>
          {!withHardware && <ConfiguratorFlexOverlay />}
        </ConfiguratorFlexSection>
      </ConfiguratorWrapper>
      <SoftwareSolutions>
        <SoftwareSolutionsInner>
          <h3>Software solutions</h3>
          <Cards3>
            <SoftwareCard
              onClick={() => setPeriod("6months")}
              selected={period === "6months"}
            >
              <h4>Pro 6 months</h4>
              <Price
                useType={"racing"}
                boatType={"coachboat"}
                period={"6months"}
              >
                per
                <br />6 months
              </Price>
              <ul>
                <CardListElement>Smartphone integration</CardListElement>
                <CardListElement>Realtime wind statistics</CardListElement>
                <CardListElement>Trends and data filtering</CardListElement>
                <CardListElement>Data logger for post session analysis</CardListElement>
                <CardListElement>Remote coaching</CardListElement>
              </ul>
              <p>up to 1 managed boat slots</p>
              <Button
                primary
                disabled={!isAvailable("racing", "dinghy", "6months")}
                onClick={() =>
                  handleSoftwareChange("racing", "coachboat", "6months")
                }
              >
                {isAvailable("leisure", "yacht", "basic")
                  ? `ADD TO CART`
                  : `UNAVAILABLE`}
              </Button>
            </SoftwareCard>
            <SoftwareCard
              onClick={() => setPeriod("1year")}
              selected={period === "1year"}
            >
              <h4>Pro 1 year</h4>
              <Price useType={"racing"} boatType={"coachboat"} period={"1year"}>
                per
                <br />1 year
              </Price>
              <ul>
                <CardListElement>Smartphone integration</CardListElement>
                <CardListElement>Realtime wind statistics</CardListElement>
                <CardListElement>Trends and data filtering</CardListElement>
                <CardListElement>Data logger for post session analysis</CardListElement>
                <CardListElement>Remote coaching</CardListElement>
              </ul>
              <p>up to 2 managed boat slots</p>
              <Button
                primary
                disabled={!isAvailable("racing", "dinghy", "6months")}
                onClick={() =>
                  handleSoftwareChange("racing", "coachboat", "1year")
                }
              >
                {isAvailable("leisure", "yacht", "basic")
                  ? `ADD TO CART`
                  : `UNAVAILABLE`}
              </Button>
            </SoftwareCard>
            <SoftwareCard
              onClick={() => setPeriod("lifetime")}
              selected={period === "lifetime"}
            >
              <h4>Pro lifetime</h4>
              <Price
                useType={"racing"}
                boatType={"coachboat"}
                period={"lifetime"}
              >
                for
                <br />
                life
              </Price>
              <ul>
                <CardListElement>Smartphone integration</CardListElement>
                <CardListElement>Realtime wind statistics</CardListElement>
                <CardListElement>Trends and data filtering</CardListElement>
                <CardListElement>Data logger for post session analysis</CardListElement>
                <CardListElement>Remote coaching</CardListElement>
              </ul>
              <p>up to 3 managed boat slots</p>
              <Button
                primary
                disabled={!isAvailable("racing", "dinghy", "6months")}
                onClick={() =>
                  handleSoftwareChange("racing", "coachboat", "lifetime")
                }
              >
                {isAvailable("leisure", "yacht", "basic")
                  ? `ADD TO CART`
                  : `UNAVAILABLE`}
              </Button>
            </SoftwareCard>
          </Cards3>
        </SoftwareSolutionsInner>
      </SoftwareSolutions>
    </>
  );
};

export default YachtPackages;
