import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FaCheck } from "react-icons/fa";

import { Button } from "../../styles/ui.styles";

import {
  ConfiguratorFlexOverlay,
  ConfiguratorFlexSection,
  ConfiguratorWrapper,
  SoftwareSolutions,
  SoftwareSolutionsInner,
  StyledListElement,
  WantHardware,
  WantHardwareCheckbox,
  Cards4,
  SoftwareCard,
  CardListElement,
  CardListElementDisabled,
} from "../../styles/configurator.styles";

import Price from "./price";
import {
  isAvailable,
  findCorrespondingHardwareId,
  findSoftwareId,
  useCartStore,
} from "../../lib/store";
import { useSnackbar } from "notistack";
import { Prettify } from "../../lib/prettify";
import products from "../../../content/products/products.json";

interface Props {
  useType: UseType;
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
}

const DinghyPackages: React.FC<Props> = (props) => {
  const { useType, period, setPeriod } = props;
  const { withHardware, setWithHardware, setSoftware, software, hardware } =
    useCartStore();

  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      autoHideDuration: 3000,
      variant: "success",
      className: "snackbar",
    });
  };

  const handleSoftwareChange = (
    useType: UseType,
    boatType: BoatType,
    period: Period
  ) => {
    setSoftware(findSoftwareId(useType, boatType, period));
    // if the software is already in the cart no need to do anything
    if (software?.id === findSoftwareId(useType, boatType, period)) {
      showSnackbar("This packages is already in the cart");
    } else {
      // if there was already a software in the cart
      if (software) {
        showSnackbar(
          `Updated DataBay package to ${Prettify(boatType)} - ${Prettify(
            useType
          )} (paid ${Prettify(period)})`
        );
        // also show notification for added hardware but only when not already in the cart
        if (
          withHardware &&
          hardware?.id !==
            findCorrespondingHardwareId(
              findSoftwareId(useType, boatType, period)
            )
        ) {
          showSnackbar(`Updated hardware to ${Prettify(boatType)}`);
        }
      } else {
        showSnackbar(
          `Added Databay for ${Prettify(boatType)} - ${Prettify(
            useType
          )} to the cart (paid ${Prettify(period)})`
        );
        // also show notification for added hardware
        if (withHardware) {
          showSnackbar(`Added ${Prettify(boatType)} hardware to the cart`);
        }
      }
    }
  };

  const handleWantHardware = () => {
    // * if hardware is not available, don't do anything
    if (software) {
      if (
        products.find(
          (node) => node.id === findCorrespondingHardwareId(software.id)
        )?.available === false
      ) {
        setWithHardware(false);
        return;
      }
    }
    const hardwareBefore = hardware;
    // It is possible that the button is clicked
    setWithHardware(!withHardware);
    // only show snackbar if adding hardware, so withHardware was false before
    // also the button can be clicked while a databay for a different boat type is in the cart so tell that to the user
    if (withHardware === false && software) {
      const name = Prettify(
        products.find((node) => node.id === software?.id)?.boatType as BoatType
      );
      showSnackbar(
        `Added ${name} hardware to match the Databay currently in the cart`
      );
    }
    // show removed snackbar if there was a hardware in the cart before
    if (hardwareBefore) {
      const name = Prettify(
        products.find((node) => node.id === software?.id)?.boatType as BoatType
      );
      showSnackbar(`Removed ${name} hardware from the cart`);
    }
  };

  return (
    <>
      <ConfiguratorWrapper>
        <WantHardware>
          <WantHardwareCheckbox
            checked={!withHardware}
            onChange={handleWantHardware}
            className="custom"
            id="checkbox"
            type="checkbox"
          />
          <p>I am only looking for software solutions</p>
        </WantHardware>
        <ConfiguratorFlexSection>
          <div>
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              src={"../../images/dinghy_edition.jpg"}
              alt={"Dinghy Edition"}
              objectFit={"contain"}
            />
          </div>
          <div>
            <h3>Dinghy Edition Details</h3>
            <p>
              The Dinghy Edition onboard unit allows sailing sessions to be more
              effective, by having an instantly accessible view of the boat
              motion data, weather conditions and data analytics-based insights
              to maximize boat performance.
            </p>
            <ul>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Accuracy
                </StyledListElement>
                <p>
                  Fastrrr’s cutting edge industrial grade GPS and sensor fusion
                  technology gives outstanding accuracy.
                </p>
              </li>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Light weight
                </StyledListElement>
                <p>
                  On high performance racing boats every gram counts. We
                  designed our product to be as light as possible.
                </p>
              </li>
            </ul>
            <Link to={"/products/dinghy-edition"}>
              {/* <Button primary={true}>MORE DETAILS</Button> */}
            </Link>
          </div>
          {/* <ConfiguratorFlexOverlay hidden={!withHardware} /> */}
          {!withHardware && <ConfiguratorFlexOverlay />}
        </ConfiguratorFlexSection>
      </ConfiguratorWrapper>
      <SoftwareSolutions>
        <SoftwareSolutionsInner>
          <h3>Software solutions</h3>
          {useType === "leisure" && (
            <Cards4>
              <SoftwareCard
                onClick={() => setPeriod("basic")}
                selected={period === "basic"}
              >
                <h4>Basic</h4>
                <Price
                  useType={"leisure"}
                  boatType={"dinghy"}
                  period={"basic"}
                />
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Limited Databay access</CardListElement>
                  <CardListElementDisabled>
                    Polar Charts
                  </CardListElementDisabled>
                  <CardListElementDisabled>Maneuvers</CardListElementDisabled>
                  <CardListElementDisabled>
                    Sail settings
                  </CardListElementDisabled>
                  <CardListElementDisabled>
                    Captain’s Logbook
                  </CardListElementDisabled>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("leisure", "dinghy", "basic")}
                  onClick={() =>
                    handleSoftwareChange("leisure", "dinghy", "basic")
                  }
                >
                  {isAvailable("leisure", "dinghy", "basic")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("1month")}
                selected={period === "1month"}
              >
                <h4>Pro 1 month</h4>
                <Price
                  useType={"leisure"}
                  boatType={"dinghy"}
                  period={"1month"}
                >
                  per
                  <br />
                  month
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("leisure", "dinghy", "1month")}
                  onClick={() =>
                    handleSoftwareChange("leisure", "dinghy", "1month")
                  }
                >
                  {isAvailable("leisure", "dinghy", "1month")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("3months")}
                selected={period === "3months"}
              >
                <h4>Pro 3 months</h4>
                <Price
                  useType={"leisure"}
                  boatType={"dinghy"}
                  period={"3months"}
                >
                  per
                  <br />3 months
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("leisure", "dinghy", "3months")}
                  onClick={() =>
                    handleSoftwareChange("leisure", "dinghy", "3months")
                  }
                >
                  {isAvailable("leisure", "dinghy", "3months")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("6months")}
                selected={period === "6months"}
              >
                <h4>Pro 6 months</h4>
                <Price
                  useType={"leisure"}
                  boatType={"dinghy"}
                  period={"6months"}
                >
                  per
                  <br />6 months
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("leisure", "dinghy", "3months")}
                  onClick={() =>
                    handleSoftwareChange("leisure", "dinghy", "6months")
                  }
                >
                  {isAvailable("leisure", "dinghy", "6months")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
            </Cards4>
          )}
          {useType === "racing" && (
            <Cards4>
              <SoftwareCard
                onClick={() => setPeriod("basic")}
                selected={period === "basic"}
              >
                <h4>Basic</h4>
                <Price
                  useType={"racing"}
                  boatType={"dinghy"}
                  period={"basic"}
                />
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Limited Databay access</CardListElement>
                  <CardListElementDisabled>
                    Polar Charts
                  </CardListElementDisabled>
                  <CardListElementDisabled>Maneuvers</CardListElementDisabled>
                  <CardListElementDisabled>
                    Sail settings
                  </CardListElementDisabled>
                  <CardListElementDisabled>
                    Captain’s Logbook
                  </CardListElementDisabled>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("racing", "dinghy", "basic")}
                  onClick={() =>
                    handleSoftwareChange("racing", "dinghy", "basic")
                  }
                >
                  {isAvailable("racing", "dinghy", "basic")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("1month")}
                selected={period === "1month"}
              >
                <h4>Pro 1 month</h4>
                <Price useType={"racing"} boatType={"dinghy"} period={"1month"}>
                  per
                  <br />1 month
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("racing", "dinghy", "1month")}
                  onClick={() =>
                    handleSoftwareChange("racing", "dinghy", "1month")
                  }
                >
                  {isAvailable("racing", "dinghy", "1month")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("3month")}
                selected={period === "3month"}
              >
                <h4>Pro 3 months</h4>
                <Price
                  useType={"racing"}
                  boatType={"dinghy"}
                  period={"3month"}
                >
                  per
                  <br />3 months
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("racing", "dinghy", "3month")}
                  onClick={() =>
                    handleSoftwareChange("racing", "dinghy", "3month")
                  }
                >
                  {isAvailable("racing", "dinghy", "3month")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("6month")}
                selected={period === "6month"}
              >
                <h4>Pro 6 months</h4>
                <Price
                  useType={"racing"}
                  boatType={"dinghy"}
                  period={"6month"}
                >
                  per
                  <br />6 months
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("racing", "dinghy", "6month")}
                  onClick={() =>
                    handleSoftwareChange("racing", "dinghy", "6month")
                  }
                >
                  {isAvailable("racing", "dinghy", "6month")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
            </Cards4>
          )}
        </SoftwareSolutionsInner>
      </SoftwareSolutions>
    </>
  );
};

export default DinghyPackages;
