import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Select from "react-select";
import type { SingleValue } from "react-select";

import DinghyPackages from "./dinghyPackages";
import YachtPackages from "./yachtPackages";
import CoachBoatPackages from "./coachboatPackages";
import { DoubleTitle } from "../../styles/ui.styles";
import { useCartStore } from "../../lib/store";

interface Props {
  forBoatType?: BoatType;
}

const Configurator: React.FC<Props> = (props) => {
  const { useTypeDefault, setUseTypeDefault } = useCartStore();
  // limit functionality if this component is on a product page
  const { forBoatType } = props;
  // state for storing use type
  const [useType, setUseType] = useState<UseType>(useTypeDefault);
  // list of select element values
  const boatTypes = [
    { value: "dinghy", label: "Dinghy", isDisabled: false },
    { value: "yacht", label: "Yacht", isDisabled: false },
    {
      value: "coachboat",
      label: "Coachboat",
      isDisabled: useType === "leisure" ? true : false,
    },
  ];

  const [selectedBoatType, setSelectedBoatType] = useState<
    SingleValue<{ value: string; label: string; isDisabled: boolean }>
  >(boatTypes[0]);

  // * we can use this component for the product page with disabbled boat type selection
  useEffect(() => {
    if (forBoatType) {
      switch (forBoatType) {
        case "dinghy":
          setSelectedBoatType({
            value: "dinghy",
            label: "Dinghy",
            isDisabled: false,
          });
          break;
        case "yacht":
          setSelectedBoatType({
            value: "yacht",
            label: "Yacht",
            isDisabled: false,
          });
          break;

        case "coachboat":
          changeUseType("racing");
          setSelectedBoatType({
            value: "coachboat",
            label: "Coachboat",
            isDisabled: false,
          });
          break;

        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    // reset boat type selection since leisure-coachboat is not valid
    if (useType === "leisure" && selectedBoatType?.value === "coachboat") {
      setSelectedBoatType({
        value: "dinghy",
        label: "Dinghy",
        isDisabled: false,
      });
    }
  }, [useType]);

  const [period, setPeriod] = useState<Period>("1month");

  const changeUseType = (useType: UseType) => {
    setUseTypeDefault(useType);
    setUseType(useType);
  };

  return (
    <>
      <Wrapper>
        <DoubleTitle normalTitle="Packages" largeTitle="PACKAGES" />
        {/* // * disable type selection on coachboat product page */}
        {forBoatType !== "coachboat" && (
          <>
            <h3>Select category</h3>
            <ButtonGroup>
              <div>
                <input
                  type="radio"
                  value="racing"
                  name="useType"
                  checked={useType === "racing"}
                  readOnly={true} // controlled by clicking the label
                />
                <label onClick={() => changeUseType("racing")} htmlFor="racing">
                  Racing
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  value="leisure"
                  name="useType"
                  checked={useType === "leisure"}
                  readOnly={true} // controlled by clicking the label
                />
                <label
                  onClick={() => changeUseType("leisure")}
                  htmlFor="leisure"
                >
                  Leisure
                </label>
              </div>
            </ButtonGroup>
          </>
        )}
        {/* // * disable boat selection on product page */}
        {!forBoatType && (
          <>
            <h3>Pick your boat type</h3>
            <SelectWrapper>
              <Select
                classNamePrefix={"type-select"}
                options={boatTypes}
                isSearchable={false}
                value={selectedBoatType}
                onChange={(newVal) => setSelectedBoatType(newVal)}
              />
            </SelectWrapper>
          </>
        )}
      </Wrapper>
      {selectedBoatType?.value === "dinghy" && (
        <DinghyPackages
          useType={useType}
          period={period}
          setPeriod={setPeriod}
        />
      )}
      {selectedBoatType?.value === "yacht" && (
        <YachtPackages
          useType={useType}
          period={period}
          setPeriod={setPeriod}
        />
      )}
      {selectedBoatType?.value === "coachboat" && (
        <CoachBoatPackages period={period} setPeriod={setPeriod} />
      )}
    </>
  );
};

export default Configurator;

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px var(--gutter);

  h3 {
    text-align: center;
  }
`;

const ButtonGroup = styled.div`
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  height: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-radius: 22px;
  overflow: hidden;
  box-shadow: var(--shadow);

  input {
    display: none;
    + label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-weight: 400;
      background: #fff;
      color: #000;
      cursor: pointer;
      transition: 0.2s;
    }

    &:checked + label {
      background: var(--color-orange);
      color: #fff;
    }
  }

  @media (max-width: 480px) {
    height: 44px;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .type-select__control {
    width: 100%;
    border: 1px solid var(--color-orange-hovered);
    border-radius: 99px;
    font-size: 1.6rem;
    @media (min-width: 480px) {
      font-size: calc(1.6rem + ((1vw - 0.48rem) * 0.5556));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 2rem;
    }
    box-shadow: none;

    transition: 0.2s;
    &:hover {
      box-shadow: none;
      border: 1px solid var(--color-orange);
    }
  }

  .type-select__value-container {
    padding-left: 16px;
  }

  .type-select__control--is-focused {
    box-shadow: none;
    border: 1px solid var(--color-orange);
  }

  .type-select__menu-list {
    padding: 0;
    background: #fff;
    border: 1px solid var(--color-orange);
    border-radius: 22px;
  }

  .type-select__menu {
    width: 400px;
    height: 44px;
    font-size: 1.6rem;
    @media (min-width: 480px) {
      font-size: calc(1.6rem + ((1vw - 0.48rem) * 0.5556));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 2rem;
    }

    border: unset;
    box-shadow: none;
  }

  .type-select__option {
    padding-left: 16px;
    background-color: #fff;
    cursor: pointer;
    &:active {
      background-color: #fff;
    }
  }
  .type-select__option--is-focused {
    background-color: rgba(248, 51, 28, 0.1);
  }
  .type-select__option--is-selected {
    background-color: var(--color-orange);
  }
`;
