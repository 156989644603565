import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaCheck } from "react-icons/fa";

import { Button } from "../../styles/ui.styles";

import {
  ConfiguratorFlexOverlay,
  ConfiguratorFlexSection,
  ConfiguratorWrapper,
  SoftwareSolutions,
  SoftwareSolutionsInner,
  StyledListElement,
  WantHardware,
  WantHardwareCheckbox,
  Cards4,
  SoftwareCard,
  CardListElement,
  CardListElementDisabled,
} from "../../styles/configurator.styles";

import Price from "./price";
import {
  findCorrespondingHardwareId,
  findSoftwareId,
  isAvailable,
  useCartStore,
} from "../../lib/store";
import { useSnackbar } from "notistack";
import { Prettify } from "../../lib/prettify";
import products from "../../../content/products/products.json";

interface Props {
  useType: UseType;
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
}

const YachtPackages: React.FC<Props> = (props) => {
  const { useType, period, setPeriod } = props;
  const { withHardware, setWithHardware, setSoftware, software, hardware } =
    useCartStore();

  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string) => {
    enqueueSnackbar(message, {
      autoHideDuration: 3000,
      variant: "success",
      className: "snackbar",
    });
  };

  const handleSoftwareChange = (
    useType: UseType,
    boatType: BoatType,
    period: Period
  ) => {
    setSoftware(findSoftwareId(useType, boatType, period));
    // if the software is already in the cart no need to do anything
    if (software?.id === findSoftwareId(useType, boatType, period)) {
      showSnackbar("This packages is already in the cart");
    } else {
      // if there was already a software in the cart
      if (software) {
        showSnackbar(
          `Updated DataBay package to ${Prettify(boatType)} - ${Prettify(
            useType
          )} (paid ${Prettify(period)})`
        );
        // also show notification for added hardware but only when not already in the cart
        if (
          withHardware &&
          hardware?.id !==
            findCorrespondingHardwareId(
              findSoftwareId(useType, boatType, period)
            )
        ) {
          showSnackbar(`Updated hardware to ${Prettify(boatType)}`);
        }
      } else {
        showSnackbar(
          `Added Databay for ${Prettify(boatType)} - ${Prettify(
            useType
          )} to the cart (paid ${Prettify(period)})`
        );
        // also show notification for added hardware
        if (withHardware) {
          showSnackbar(`Added ${Prettify(boatType)} hardware to the cart`);
        }
      }
    }
  };

  const handleWantHardware = () => {
    // * if hardware is not available, don't do anything
    if (software) {
      if (
        products.find(
          (node) => node.id === findCorrespondingHardwareId(software.id)
        )?.available === false
      ) {
        setWithHardware(false);
        return;
      }
    }

    const hardwareBefore = hardware;
    // It is possible that the button is clicked
    setWithHardware(!withHardware);
    // only show snackbar if adding hardware, so withHardware was false before
    // also the button can be clicked while a databay for a different boat type is in the cart so tell that to the user
    if (withHardware === false && software) {
      const name = Prettify(
        products.find((node) => node.id === software?.id)?.boatType as BoatType
      );
      showSnackbar(
        `Added ${name} hardware to match the Databay currently in the cart`
      );
    }
    // show removed snackbar if there was a hardware in the cart before
    if (hardwareBefore) {
      const name = Prettify(
        products.find((node) => node.id === software?.id)?.boatType as BoatType
      );
      showSnackbar(`Removed ${name} hardware from the cart`);
    }
  };

  return (
    <>
      <ConfiguratorWrapper>
        <WantHardware>
          <WantHardwareCheckbox
            checked={!withHardware}
            onChange={handleWantHardware}
            className="custom"
            id="checkbox"
            type="checkbox"
          />
          <p>I am only looking for software solutions</p>
        </WantHardware>
        <ConfiguratorFlexSection>
          <div>
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              src={"../../images/yacht_edition.jpg"}
              alt={"Yacht Edition"}
              objectFit={"contain"}
            />
          </div>
          <div>
            <h3>Yacht Edition Details</h3>
            <p>
              Fastrrr offers a refined and efficient training experience through
              gathering training data and providing insights based on the AI
              enhanced analytics solution, which will help professional sailors
              achieve better results and maximize their boats performance.
            </p>
            <ul>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Compatibility
                </StyledListElement>
                <p>NMEA2000 and NMEA0183 compatible</p>
              </li>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Sail Chart Assistant
                </StyledListElement>
                <p>
                  Allows you to choose the optimal sails for any given
                  situations.
                </p>
              </li>
              <li>
                <StyledListElement>
                  <FaCheck />
                  Boat settings log
                </StyledListElement>
                <p>
                  Never lose a sail or boat setting, store everything in the
                  cloud.
                </p>
              </li>
            </ul>
            {/* <Button primary={true}>MORE DETAILS</Button> */}
          </div>
          {/* <ConfiguratorFlexOverlay hidden={!withHardware} /> */}
          {!withHardware && <ConfiguratorFlexOverlay />}
        </ConfiguratorFlexSection>
      </ConfiguratorWrapper>
      <SoftwareSolutions>
        <SoftwareSolutionsInner>
          <h3>Software solutions</h3>
          {useType === "leisure" && (
            <Cards4>
              <SoftwareCard
                onClick={() => setPeriod("basic")}
                selected={period === "basic"}
              >
                <h4>Basic</h4>
                <Price
                  useType={"leisure"}
                  boatType={"yacht"}
                  period={"basic"}
                />
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Limited Databay access</CardListElement>
                  <CardListElementDisabled>
                    Polar Charts
                  </CardListElementDisabled>
                  <CardListElementDisabled>Maneuvers</CardListElementDisabled>
                  <CardListElementDisabled>
                    Sail settings
                  </CardListElementDisabled>
                  <CardListElementDisabled>
                    Captain’s Logbook
                  </CardListElementDisabled>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("leisure", "yacht", "basic")}
                  onClick={() =>
                    handleSoftwareChange("leisure", "yacht", "basic")
                  }
                >
                  {isAvailable("leisure", "yacht", "basic")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("1month")}
                selected={period === "1month"}
              >
                <h4>Pro 1 month</h4>
                <Price useType={"leisure"} boatType={"yacht"} period={"1month"}>
                  per
                  <br />1 month
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("leisure", "yacht", "1month")}
                  onClick={() =>
                    handleSoftwareChange("leisure", "yacht", "1month")
                  }
                >
                  {isAvailable("leisure", "yacht", "1month")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("3months")}
                selected={period === "3months"}
              >
                <h4>Pro 3 months</h4>
                <Price
                  useType={"leisure"}
                  boatType={"yacht"}
                  period={"3months"}
                >
                  per
                  <br />3 months
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("leisure", "yacht", "3months")}
                  onClick={() =>
                    handleSoftwareChange("leisure", "yacht", "3months")
                  }
                >
                  {isAvailable("leisure", "yacht", "3months")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("6months")}
                selected={period === "6months"}
              >
                <h4>Pro 6 months</h4>
                <Price
                  useType={"leisure"}
                  boatType={"yacht"}
                  period={"6months"}
                >
                  per
                  <br />6 months
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("leisure", "yacht", "6months")}
                  onClick={() =>
                    handleSoftwareChange("leisure", "yacht", "6months")
                  }
                >
                  {isAvailable("leisure", "yacht", "6months")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
            </Cards4>
          )}
          {useType === "racing" && (
            <Cards4>
              <SoftwareCard
                onClick={() => setPeriod("basic")}
                selected={period === "basic"}
              >
                <h4>Basic</h4>
                <Price useType={"racing"} boatType={"yacht"} period={"basic"} />
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Limited Databay access</CardListElement>
                  <CardListElementDisabled>
                    Polar Charts
                  </CardListElementDisabled>
                  <CardListElementDisabled>Maneuvers</CardListElementDisabled>
                  <CardListElementDisabled>
                    Sail settings
                  </CardListElementDisabled>
                  <CardListElementDisabled>
                    Captain’s Logbook
                  </CardListElementDisabled>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("racing", "yacht", "basic")}
                  onClick={() =>
                    handleSoftwareChange("racing", "yacht", "basic")
                  }
                >
                  {isAvailable("racing", "yacht", "basic")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("1month")}
                selected={period === "1month"}
              >
                <h4>Pro 1 month</h4>
                <Price useType={"racing"} boatType={"yacht"} period={"1month"}>
                  per
                  <br />1 month
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("racing", "yacht", "1month")}
                  onClick={() =>
                    handleSoftwareChange("racing", "yacht", "1month")
                  }
                >
                  {isAvailable("racing", "yacht", "1month")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("3months")}
                selected={period === "3months"}
              >
                <h4>Pro 3 months</h4>
                <Price useType={"racing"} boatType={"yacht"} period={"3months"}>
                  per
                  <br />3 months
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("racing", "yacht", "3months")}
                  onClick={() =>
                    handleSoftwareChange("racing", "yacht", "3months")
                  }
                >
                  {isAvailable("racing", "yacht", "3months")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
              <SoftwareCard
                onClick={() => setPeriod("6months")}
                selected={period === "6months"}
              >
                <h4>Pro 6 months</h4>
                <Price useType={"racing"} boatType={"yacht"} period={"6months"}>
                  per
                  <br />6 months
                </Price>
                <ul>
                  <CardListElement>
                    Compatible with third party hardware
                  </CardListElement>
                  <CardListElement>Real time visualization</CardListElement>
                  <CardListElement>Unlimited Databay access</CardListElement>
                  <CardListElement>Share Data with your Team</CardListElement>
                  <CardListElement>Polar Charts</CardListElement>
                  <CardListElement>Maneuvers</CardListElement>
                  <CardListElement>Sail settings</CardListElement>
                  <CardListElement>Captain’s Logbook</CardListElement>
                </ul>
                <Button
                  primary={true}
                  disabled={!isAvailable("racing", "yacht", "6months")}
                  onClick={() =>
                    handleSoftwareChange("racing", "yacht", "6months")
                  }
                >
                  {isAvailable("racing", "yacht", "6months")
                    ? `ADD TO CART`
                    : `UNAVAILABLE`}
                </Button>
              </SoftwareCard>
            </Cards4>
          )}
        </SoftwareSolutionsInner>
      </SoftwareSolutions>
    </>
  );
};

export default YachtPackages;
